<template>
    <div>
        <div style="width: 580px; text-align: left;">
            <el-input v-model="searchContractKeyWords" placeholder="请输入关键词" @input="onSearchInputEnter"/>
        </div>
        <el-table
                ref="singleTableRef"
                :data="contracts"
                highlight-current-row
                show-overflow-tooltip="true"
                style="width: 100%"
                height="250"
                class="contract-table">
            <el-table-column width="55">
                <template #default="scope">
                    <el-checkbox v-model="scope.row.selected"
                                 label=""
                                 :checked="selectedContract && selectedContract.id === scope.row.id"
                                 @change="handleCurrentChange(scope.row)"/>
                </template>
            </el-table-column>

            <el-table-column property="title" label="标题" min-width="160">
                <template #default="scope">
                    <div style="display: flex;align-items: center">
                        <div v-if="statusVisible">
                            <span v-if="scope.row.approvalStatus==ContractApprovalStatus.DRAFT"
                                  class="stage-circle stage-gray"></span>
                            <span v-else-if="scope.row.approvalStatus==ContractApprovalStatus.LAUNCH_READY_TO_SIGN"
                                  class="stage-circle stage-yellow"></span>
                            <span v-else-if="scope.row.approvalStatus==ContractApprovalStatus.LAUNCH_READY_TO_SIGN_PASSED"
                                  class="stage-circle stage-purple"></span>
                            <span v-else-if="scope.row.approvalStatus==ContractApprovalStatus.LAUNCH_READY_TO_SIGN_REFUSED"
                                  class="stage-circle stage-red"></span>
                            <span v-else-if="scope.row.approvalStatus==ContractApprovalStatus.LAUNCH_SIGNED_PASSED"
                                  class="stage-circle stage-green"></span>
                            <span v-else-if="scope.row.approvalStatus==ContractApprovalStatus.CANCELED"
                                  class="stage-circle stage-black"></span>
                            <span v-else-if="scope.row.approvalStatus==ContractApprovalStatus.PRO"
                                  class="stage-circle stage-white"></span>
                        </div>
                        <div>{{scope.row.title}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column property="updateTime" label="当事人" min-width="160">
                <template #default="scope">
                    <span v-if="!scope.row.otherParties || scope.row.otherParties.length === 0"></span>
                    <div v-else class="otherParties"
                         style="padding: 10px 0 10px 0;height: 46px;display: flex;align-items: center">
                        <el-tooltip placement="top">
                            <template #content>
                                <span>{{scope.row.otherParties.join("、")}}</span>
                            </template>
                            <el-tag size="small"
                                    type="info"
                                    style="color: #101010; font-weight: 400; width: 130px;"
                                    v-html="getContractPartiesForTable(scope.row.otherParties)">
                            </el-tag>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
            <el-table-column property="updateTime" label="最近更新时间" min-width="160">
                <template #default="scope">
                    <div>
                        <span v-html="_transformTimeInfo(scope.row.updateDate, new Date().getTime())"></span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import {getResourceList} from "../../api/api";
    import {MetaMessage, transformTimeInfo} from "../../util";
    import {ContractApprovalStatus} from "../../constant/contract";

    const CLMResourceType = {
        PRO: 1,
        PUBLISHED_TEMPLATE: 2,
        CONTRACT: 4,
        ALL: 7
    }
    export default {
        name: "ContractSelector",
        props: ['initRecentlyModified', 'statusVisible', 'statusList'],
        components: {},
        data() {
            return {
                CLMResourceType,
                recentlyModifiedContracts: [],
                contracts: [],
                ContractApprovalStatus,
                searchContractKeyWords: '',
                searchTime: null,
                selectedContract: null,
                metaMessage: new MetaMessage(),
            }
        },
        created() {

        },
        mounted() {
            console.log('contractSelector   mounted --------- ')
            if (this.initRecentlyModified === true) {
                this.init();
            }

            //自定义wrap元素的class 加上 'custom-scroller'
            const tableBodyWrapper = this.$refs.singleTableRef.$el.querySelector('.el-table__body-wrapper');
            tableBodyWrapper.classList.add('custom-scroller');
            tableBodyWrapper.onscroll = () => {
                let list = document.getElementsByClassName('el-popper');
                for (const item of list) {
                    item.style.display = 'none';
                }
            }
        },
        beforeUnmount() {
        },
        watch: {},
        methods: {
            init: function () {
                const params = {
                    statuses: this.statusList,
                    trashed: false,
                    resourceType: CLMResourceType.CONTRACT,
                    sortByTime: true
                };
                getResourceList(params).then(res => {
                    for (const item of res.data.data.content) {
                        let contract = JSON.parse(JSON.stringify(item));
                        contract.updateDate = new Date(contract.updateTime).getTime();
                        if (contract.otherParties) {
                            contract.otherParties = JSON.parse(contract.otherParties);
                        }
                        this.recentlyModifiedContracts.push(contract);
                        this.contracts = JSON.parse(JSON.stringify(this.recentlyModifiedContracts));
                    }
                }).catch(err => {
                    console.error('getContractRecentlyModified err:', err);
                })
            },
            onSearchInputEnter: function () {
                this.searchTime = new Date().getTime();
                if (!this.searchContractKeyWords) {
                    // this.metaMessage['info']('请输入关键词');
                    this.contracts = [];
                    return;
                }
                console.log('onSearchInputEnter  this.searchContractKeyWords:', this.searchContractKeyWords);
                const params = {
                    statuses: this.statusList,
                    keywords: this.searchContractKeyWords,
                    trashed: false,
                    resourceType: CLMResourceType.CONTRACT,
                    sortByTime: true,
                    t: this.searchTime
                };
                getResourceList(params).then(res => {
                    if (this.searchTime === res.data.timestamp) {
                        this.contracts = [];
                        for (const item of res.data.data.content) {
                            let contract = JSON.parse(JSON.stringify(item));
                            contract.updateDate = new Date(contract.updateTime).getTime();
                            if (contract.otherParties) {
                                contract.otherParties = JSON.parse(contract.otherParties);
                            }
                            this.contracts.push(contract);
                        }
                    }
                }).catch(err => {
                    console.error('getContractRecentlyModified err:', err);
                })

                // searchContractsAndFiles(this.searchContractKeyWords, this.statusFilter, '', '', this.searchTime).then(res => {
                //     if (this.searchTime === res.data.timestamp) {
                //         console.log('searchContractsAndFiles  res == ', res);
                //         this.contracts = [];
                //         for (const item of res.data.data.contractFileSearchResultItems) {
                //             // if (item.type === 'contract' && item.contractEsDTO.approvalStatus === 21) {
                //             if (item.type === 'contract') {
                //                 this.contracts.push(item.contractEsDTO);
                //             }
                //         }
                //     }
                // }).catch(err => {
                //     console.error('searchContractsAndFiles err:', err);
                //     this.metaMessage['error']('搜索合同失败');
                // })
            },
            handleCurrentChange: function (row) {
                console.log('handleCurrentChange  row:', row);
                if (row.selected) {
                    this.selectedContract = row;
                    for (const contract of this.contracts) {
                        if (contract.id != row.id) {
                            contract.selected = false;
                        }
                    }
                } else {
                    this.selectedContract = null;
                }
            },
            _transformTimeInfo(start, end) {
                return transformTimeInfo(start, end)
            },
            getContractPartiesForTable: function (parties) {
                let showParty = parties[0];
                if (showParty.length > 8) {
                    showParty = showParty.substring(0, 8) + "...";
                }
                return showParty;
            },
            clearContracts: function () {
                this.searchContractKeyWords = "";
                this.selectedContract = null;
                this.contracts = [];
                this.$nextTick(() => {
                    this.contracts = JSON.parse(JSON.stringify(this.recentlyModifiedContracts));
                    console.log('this.contracts === ', this.contracts)
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import url("../../assets/css/alert.css");


    .contract-checker-container {
        padding-top: 10px;
        min-height: 300px;
    }

    .flex {
        display: flex;
        line-height: 30px;
    }

    .setting-key {
        width: 100px;
    }

</style>
